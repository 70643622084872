import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import { approveAction, getAllowanceDetails, JoinNowAction } from "../Action/ContractActions.js";
import { useSelector } from "react-redux";
import { toastAlert } from "../helper/toastAlert.js";
import isEmpty from "is-empty";
import { useParams } from "react-router-dom";
import config from '../config/config'
import { connection } from "../helper/connection.js";
import { updatemissed } from "../Action/apiAction.js";
import DefiQABI from '../ABI/DefiQABI.json';
import { type WalletClient, useWalletClient } from 'wagmi'
import Web3 from "web3";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Disclaimer(props) {
  var { id } = useParams()

  const [referalAddress, setReferalAddress] = useState("")
  const [Error, setError] = useState()
  const [load, setload] = useState(false)
  const [loadapprove, setloadapprove] = useState(false)
  const [approve, setApprove] = useState()
  const walletconnection = useSelector((state) => state.walletConnection);
  let chainid = config.NetworkId ;
  const { data: walletClient } = useWalletClient({ chainid })

  useEffect(() => {
    setRefAddress()
    getAllowance()
  }, [walletClient])


  const handlechange = (e) => {
    const { value } = e.target;
    setError()
    setReferalAddress(value)
  }


  async function getregister(datas){
    var get = await connection();
    if(get && get.web3){
      let web3 =  new Web3(get.web3);
      let address = get.address;
      var binanceContract = new web3.eth.Contract(DefiQABI, config.DefiQAddress);
      let userrefdetails = await binanceContract.methods.planB(address).call();
      let referralparent = await binanceContract.methods.userList(userrefdetails.referrerID).call();
      let rec = {
        address : datas.address,
        userid  : datas.userdetails.id,
        referralparent  : referralparent,
        level   : datas.userdetails.currentLevel
      }
      await updatemissed(rec);
    }
  }


  const setRefAddress = () => {
    if (!isEmpty(id)) {
      setReferalAddress(id)
    }
  }
  const getAllowance = async () => {
    var { value, joinamt, status } = await getAllowanceDetails(config.BUSDAddress, config.DefiQAddress)
    console.log('joinamaaaaaaaaaaaat: ', joinamt);
    console.log('valueeeeeeeeedddeeeeeeeeeeees: ', value);

    if (status == true && ((parseFloat(value) <= 0) || (parseFloat(value) < parseFloat(joinamt)))) {
      // setApprove(false)///////
      setApprove(true)
      // toastAlert('error', `${'you should approve' + joinamt + 'USDT'}`)
    }
    else {
      // setApprove(true)
      setApprove(false)

    }
  }
  
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel join_now_panel">
            <div className="row">
              <div className="col-md-8 m-auto">
                <div className="title_flex">
                  <h2 className="inner_title">Disclaimer</h2>
                  <div className="terms_content">
                    <p>Invest in crypto currencies carries a high level of risk, and may not be suitable for all investors. Before deciding to participate  cryptocurrency you should carefully consider your investment objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss of some or all of your initial investment and therefore you should not invest money that you cannot afford to lose. You seek any other form of offering will not guarantee a return on your investment. </p>
                    <p>Any opinions, news, research, analyses, prices, or other information contained on this website is provided as general market commentary, and does not constitute investment advice. The defiq will not accept liability for any loss or damage, including without limitation to, any loss of profit, which may arise directly or indirectly from use of or reliance on such information. All opinions expressed on this site are owned by the respective writer and should never be considered as advice in any form.</p>
                    <p>The defiq makes no representation or warranties as to the accuracy and or timelines of the information contained herein. A qualified professional should be consulted before making any financial decisions.</p>
                    <p>As with any potential risk in cyberspace, we recommend careful consideration when dealing with anyone who may not be legitimate, and not to provide personal or financial information to, or invest in, schemes which may be illegitimate.  DEFIQ accepts no liability whatsoever in respect of any such issues.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
