//let NODE_ENV = 'production';
let NODE_ENV = "production";

let Front_URL = "";
let Server_URL = "";

let baseUrl = "";
let BUSDAddress = "";
let DefiQAddress = "";

let BNBSYMBOL = "";
let toFixedSwap = "";
let toFixedBal = "";
let NetworkId = "";
let toFixed = "";
let networkName = "";
let toFixedvalue = "";
let txUrlAddress = "";
let txurl = "";
let tokenSymbol = "";
let AdminAddress = "";
let DefiQTokenAddress = "";
let projectId = "";

if (NODE_ENV === "production") {
  console.log("live==");
  Front_URL = "https://www.defiq.biz/";
  Server_URL = "https://prodapi.defiq.biz/";
  baseUrl = "https://prodapi.defiq.biz";
  AdminAddress = "0x1c0DB5ea22E7cc211cFF518eF66e896fC8Dc542f";
  BUSDAddress = "0x55d398326f99059ff775485246999027b3197955";
  DefiQAddress = "0x9a3A8d6EDe939B758B2924730318581aA11aF5EE";
  DefiQTokenAddress = "0x866E7B8F76CF2435Fde81DEA9FB4693Ba002DF8F";
  txUrlAddress = "https://bscscan.com/address/";
  txurl = "https://bscscan.com/tx/";
  BNBSYMBOL = "BNB";
  toFixedSwap = 18;
  toFixedvalue = 8;
  NetworkId = 56;
  toFixed = 8;
  toFixedBal = 18;
  networkName = "Binance Chain";
  tokenSymbol = "DefiQ";
  projectId = "cb89ebb21cdccb2e1b591e189e27706a"; //wallet connect
} else if (NODE_ENV === "demo") {
  Front_URL = "https://defi-q-demo.pages.dev/";
  Server_URL = "https://defiqapi.wearedev.team/";
  baseUrl = "https://defiqapi.wearedev.team";
  AdminAddress = "0xC063C6620B0494A833f4bC5919327Ba1B2831B12";
  BUSDAddress = "0x55d398326f99059ff775485246999027b3197955";
  // DefiQAddress = "0x32dD4900dCAeF45519850879f4bbd10aDE1D9136";
  DefiQAddress = "0x6AE2B01e4B6672538604D2bA9Aba7a120f694F24";
  DefiQTokenAddress = "0x006C4d9Ae59EDd1c7Dcc8a41d86B8a46881f1421";
  txUrlAddress = "https://testnet.bscscan.com/address/";
  txurl = "https://testnet.bscscan.com/tx/";
  BNBSYMBOL = "BNB";
  toFixedSwap = 18;
  toFixedvalue = 8;
  NetworkId = 97; //56--97
  toFixed = 8;
  toFixedBal = 18;
  networkName = "Binance Chain";
  tokenSymbol = "DefiQ";
  projectId = "cb89ebb21cdccb2e1b591e189e27706a"; //wallet connect
} else {
  Front_URL = "http://localhost:3000/";
  Server_URL = "http://localhost:4001/";
  baseUrl = "http://localhost:4001";
  AdminAddress = "0xC063C6620B0494A833f4bC5919327Ba1B2831B12";
  BUSDAddress = "0x55d398326f99059ff775485246999027b3197955";
  DefiQAddress = "0x6AE2B01e4B6672538604D2bA9Aba7a120f694F24";
  DefiQTokenAddress = "0x006C4d9Ae59EDd1c7Dcc8a41d86B8a46881f1421";
  txUrlAddress = "https://testnet.bscscan.com/address/";
  txurl = "https://testnet.bscscan.com/tx/";
  BNBSYMBOL = "BNB";
  toFixedSwap = 18;
  toFixedvalue = 8;
  NetworkId = 97;
  toFixed = 8;
  toFixedBal = 18;
  networkName = "Binance Chain";
  tokenSymbol = "DefiQ";
  projectId = "cb89ebb21cdccb2e1b591e189e27706a"; //wallet connect
}

let key = {
  Front_URL: Front_URL,
  Server_URL: Server_URL,
  baseUrl: baseUrl,
  AdminAddress: AdminAddress,
  BUSDAddress: BUSDAddress,
  DefiQAddress: DefiQAddress,
  BNBSYMBOL: BNBSYMBOL,
  toFixedSwap: toFixedSwap,
  toFixedBal: toFixedBal,
  toFixedvalue: toFixedvalue,
  NetworkId: NetworkId,
  toFixed: toFixed,
  networkName: networkName,
  txUrlAddress: txUrlAddress,
  txurl: txurl,
  tokenSymbol: tokenSymbol,
  DefiQTokenAddress: DefiQTokenAddress,
  projectId: projectId,
};

export default key;
