import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import { toastAlert } from "../helper/toastAlert.js";
import config from '../config/config'
import { useSelector } from "react-redux";
import { GetDetailsAction, getFormatMulticall, GetLevelDetailsAction, UpgradeAction, WithdrawAction  ,  DepositAction } from "../Action/ContractActions.js";
import { connection } from "../helper/connection.js";
import Web3 from "web3";
import { Multicall } from "ethereum-multicall";
import DefiQAbi from '../ABI/DefiQABI.json'
import { bignumber } from "../helper/customMath.js";
import syncEach from 'sync-each';
import isEmpty from "is-empty";
import { checkuser, get_eligible, get_planAlist, updatemissed , get_levelcounts } from "../Action/apiAction.js";
import { type WalletClient, useWalletClient } from 'wagmi'

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
var initialvalue = {
  teamCount : 0,
  totalearnedToken:0,
  userId: "",
  isexist: "",
  allusers: "",
  BusdBal: 0,
  totalearnAmt: 0,
  dirReferralcnt: 0,
  upgradeAmount: 0,
  upgradePending: 0,
  yourearnedAmt: 0,
  RoyaltyIncome: 0,
  locktokenAmt: 0,
  currentLevel: 0,
  genertionIncome: 0,
  upgradematrixIncome: 0,
  globalroyality1income: 0,
  globalroyality2income: 0,
  l2_missedearnings: 0,
  l3_missedearnings: 0,
  l4_missedearnings: 0,
  l5_missedearnings: 0,
  l6_missedearnings: 0,
  l7_missedearnings: 0,
  l8_missedearnings: 0,
  l9_missedearnings: 0,
  l10_missedearnings: 0
}

export default function Dashboard(props) {

  const [useraddress, setuseraddress] = useState("");
  const [upgradeLoad, setUpgradeLoad] = useState(false)
  const [depositLoad, setdepositLoad] = useState(false)
  const [withdrawLoad, setwithdrawLoad] = useState(false)
  const [userdetail, setuserdetail] = useState(initialvalue)
  const [selected_id, setselected_id] = useState("");
  const [depositamt,setdepositamt] = useState(0);
  const [level1counts,setlevel1counts] = useState(0);
  const [level2counts,setlevel2counts] = useState(0);
  const [level3counts,setlevel3counts] = useState(0);
  const [level4counts,setlevel4counts] = useState(0);
  const [level5counts,setlevel5counts] = useState(0);
  const [level6counts,setlevel6counts] = useState(0);
  const [level7counts,setlevel7counts] = useState(0);
  const [level8counts,setlevel8counts] = useState(0);
  const [level9counts,setlevel9counts] = useState(0);
  const [level10counts,setlevel10counts] = useState(0);
  const [level11counts,setlevel11counts] = useState(0);
  const [level12counts,setlevel12counts] = useState(0);
  const [level13counts,setlevel13counts] = useState(0);
  const [level14counts,setlevel14counts] = useState(0);
  const [level15counts,setlevel15counts] = useState(0);
  const [level16counts,setlevel16counts] = useState(0);
  const [level17counts,setlevel17counts] = useState(0);
  const [level18counts,setlevel18counts] = useState(0);
  const [level19counts,setlevel19counts] = useState(0);
  const [level20counts,setlevel20counts] = useState(0);
  const [level21counts,setlevel21counts] = useState(0);
  const [level22counts,setlevel22counts] = useState(0);
  const [level23counts,setlevel23counts] = useState(0);
  const [level24counts,setlevel24counts] = useState(0);
  const [level25counts,setlevel25counts] = useState(0);


  const { userId, allusers, BusdBal, totalearnAmt, dirReferralcnt, upgradeAmount, upgradePending,
    yourearnedAmt, RoyaltyIncome, locktokenAmt, currentLevel, genertionIncome, l2_missedearnings, l3_missedearnings,
    l4_missedearnings, l5_missedearnings, l6_missedearnings, l7_missedearnings,
    l8_missedearnings, l9_missedearnings, l10_missedearnings, isexist, upgradematrixIncome, globalroyality1income, globalroyality2income
  } = userdetail
  const [userdata, setdata] = useState({});
  const [treeprogress, settreeprogress] = useState(false);
  const [allselected, setallSelected] = useState({});
  const [mynetwork, setmynetwork] = useState([]);
  const [downlinefirst, setdownlinefirst] = useState([]);
  const [downlinesecond, setdownlinesecond] = useState([]);
  const [downlinethird, setdownlinethird] = useState([]);
  const [downlinefourth, setdownlinefourth] = useState([]);
  const [level1, setlevel1] = useState([]);
  const [level2, setlevel2] = useState([]);
  const [level3, setlevel3] = useState([]);
  const [selected, setSelected] = useState({});
  const [planprogress, setplanprogress] = useState(true);
  const [levelload, setlevelload] = useState(true);
  const [planrange, setplanrange] = useState([]);
  const [width, setwidth] = useState(window.innerWidth - 50);
  const [height, setheight] = useState(window.innerHeight - 50);
  const walletConnection = useSelector((state) => state.walletConnection)
  let chainid = config.NetworkId ;
  const { data: walletClient } = useWalletClient({ chainid })

  useEffect(() => {
    window.addEventListener("resize", larg);
  }, []);

  useEffect(() => {
    getdetails()
    getuserDetails()
    getlevelincomeDetails()
    getlevelcount();
  }, [walletConnection,walletClient]);

  async function getregister(datas) {

    var get = await connection();
    if (get && get.web3) {
      let web3 = new Web3(get.web3);
      let address = get.address;
      var deficontract = new web3.eth.Contract(DefiQAbi, config.DefiQAddress);
      let userrefdetails = await deficontract.methods.planB(address).call();
      let referralparent = await deficontract.methods.userList(userrefdetails.referrerID).call();
      let rec = {
        address: datas.address,
        userid: datas.userdetails.id,
        referralparent: referralparent,
        level: datas.userdetails.currentLevel
      }
      await updatemissed(rec);
    }

  }

  const getdetails = async () => {
    try {
      var datas = await connection();
      if (datas && datas.web3 && datas.address) {
        var address = datas.address;
        setdata(datas);
        if (datas.userdetails &&
          datas.userdetails.isExist) {
          let user_data = {
            address: address
          }
          // let Eligible_rec = await get_eligible();
          // if (Eligible_rec && Eligible_rec.data && Eligible_rec.data.one && Eligible_rec.data.one.length > 0) {
          //   setgpr1count(Eligible_rec.data.one.length)
          // } else {
          //   setgpr1count(0)
          // }
          // if (Eligible_rec && Eligible_rec.data && Eligible_rec.data.two && Eligible_rec.data.two.length > 0) {
          //   setgpr2count(Eligible_rec.data.two.length)
          // } else {
          //   setgpr2count(0)
          // }
          let data = await checkuser(user_data);
          if (data && data.data && data.data.address) {
            getuserDetails(datas.address)
          } else {
            getregister(datas);
            getuserDetails(datas.address)
          }
          let planAlist = await get_planAlist(user_data);
          if (planAlist && planAlist.data && planAlist.data.records) {
            setplanrange(planAlist.data.records);
            setplanprogress(false)
          } else {
            setplanrange("");
            setplanprogress(false)
          }
          // let level_counts = await get_levelcounts(user_data);
          // if(level_counts && level_counts.data && level_counts.data.records){
          //   setlevelcounts(level_counts.data.records);
          //   setlevelload(false)
          // }else{
          //   setlevelcounts("");
          //   setlevelload(false)
          // }
        } else {
          window.location.href = "/join-now";
        }
      } else {
        window.location.href = "/";
      }
    }

    catch (err) {
      console.log(err, 'errrerertttttttt')
    }

  }



  const getuserDetails = async () => {
    let address = walletConnection && walletConnection.address && walletConnection.address != '' ? walletConnection.address : ''
    setuseraddress(address)
    let isexistcheck = walletConnection && walletConnection.userdetails && walletConnection.userdetails.isExist
    if (!isEmpty(isexistcheck) && isexistcheck == false) {
      window.location.href = '/join-now'
    }
    else {
      const {
        teamCount,totalearnedToken,
        status, isexist, UserProfileId, AllUsers, BusdBal, totalearnAmt, dirReferralcount, upgradeAmount,
        upgradePending, yourearnedAmt, RoyaltyIncome, locktokenAmt, currentLevel, l2_missedearnings,
        l3_missedearnings, l4_missedearnings, l5_missedearnings, l6_missedearnings, l7_missedearnings,
        l8_missedearnings, l9_missedearnings, l10_missedearnings, genertionIncome, upgradematrixIncome, globalroyality1income, globalroyality2income, directreferralIncome
      } = await GetDetailsAction();
      if (status == true) {
        setuserdetail({
          ...userdetail, ...{
            "teamCount" : teamCount,
            "totalearnedToken" : totalearnedToken,
            userId: UserProfileId,
            isexist: isexist,
            allusers: AllUsers,
            BusdBal: BusdBal,
            totalearnAmt: totalearnAmt,
            dirReferralcnt: dirReferralcount,
            upgradeAmount: upgradeAmount,
            upgradePending: upgradePending,
            yourearnedAmt: yourearnedAmt,
            RoyaltyIncome: RoyaltyIncome,
            locktokenAmt: locktokenAmt,
            currentLevel: currentLevel,
            genertionIncome: genertionIncome,
            upgradematrixIncome: upgradematrixIncome,
            globalroyality1income: globalroyality1income,
            globalroyality2income: globalroyality2income,
            l2_missedearnings: l2_missedearnings,
            l3_missedearnings: l3_missedearnings,
            l4_missedearnings: l4_missedearnings,
            l5_missedearnings: l5_missedearnings,
            l6_missedearnings: l6_missedearnings,
            l7_missedearnings: l7_missedearnings,
            l8_missedearnings: l8_missedearnings,
            l9_missedearnings: l9_missedearnings,
            l10_missedearnings: l10_missedearnings,
            directreferralIncome: directreferralIncome
          }
        })
      }
      else {
        // window.location.href = '/'
      }
    }

  }


  const getlevelincomeDetails = async () => {
    let address = walletConnection && walletConnection.address && walletConnection.address != '' ? walletConnection.address : ''
    setuseraddress(address)
    let isexistcheck = walletConnection && walletConnection.userdetails && walletConnection.userdetails.isExist
    if (!isEmpty(isexistcheck) && isexistcheck == false) {
      window.location.href = '/join-now'
    }
    else {
      const { status } = await GetLevelDetailsAction(settreeprogress, setallSelected, setmynetwork,
        setdownlinefirst, setdownlinesecond, setdownlinethird,
        setdownlinefourth, setlevel1, setlevel2, setlevel3, setSelected);

      if (status == true) {
        // setuserdetail({
        //   ...userdetail, ...{
        //     isexist: isexist,
        //   }
        // })
      }else {
        // window.location.href = '/'
      }
    }
  }


  const Upgrade = async () => {
    setUpgradeLoad(true)
    const { status, message } = await UpgradeAction(upgradeAmount, isexist, currentLevel)
    if (status == true) {
      getuserDetails()
      setUpgradeLoad(false)
      toastAlert('success', message)
    }
    else {
      setUpgradeLoad(false)
      toastAlert('error', message)
    }
  }

  const depositamtchange = async (e) => {
    setdepositamt(e.target.value)
  }

  const deposit_busd = async () => {
    try {
      setdepositLoad(true)
      const { status, message } = await DepositAction(depositamt, isexist)
      if (status == true) {
        getuserDetails()
        setdepositLoad(false)
        toastAlert('success', message)
      }
      else {
        setdepositLoad(false)
        toastAlert('error', message)
      }
    }
    catch (err) {
      console.log(err, 'ooooooooerererere')
    }
  }

  const Withdraw = async () => {
    try {
      setwithdrawLoad(true)
      const { status, message } = await WithdrawAction(yourearnedAmt, upgradeAmount, isexist,currentLevel)
      if (status == true) {
        getuserDetails()
        setwithdrawLoad(false)
        toastAlert('success', message)
      }
      else {
        setwithdrawLoad(false)
        toastAlert('error', message)
      }
    }
    catch (err) {
      console.log(err, 'ooooooooerererere')
    }
  }

  async function larg() {
    setwidth(window.innerWidth - 50);
    setheight(window.innerHeight - 50);
  }




  async function handleClick(event, node) {

    if (node && node != null && node != undefined && node != "") {
      let myArray = node.split(" ");
      let userid = myArray[1];
      if (selected_id == userid) {

        setSelected(allselected);
        setselected_id("");
      } else {
        settreeprogress(true)
        setselected_id(userid)
        let get = await connection();
        if (get && get.web3) {
          var web3 = new Web3(get.web3)
          const multicall = new Multicall({
            web3Instance: web3,
          });
          var DefiContract = new web3.eth.Contract(DefiQAbi, config.DefiQAddress);
          let c_address = await DefiContract.methods.planBuserList(userid).call();

          let downlinenode = await DefiContract.methods.viewUserReferral(c_address).call();
          let leveldetails = await DefiContract.methods.users(c_address).call();
          let currlevel = leveldetails && leveldetails.currentLevel
          let downline1 = [];
          let downline2arr = [];
          let downline3arr = [];
          let downline4arr = [];
          let downline5arr = [];
          let downline2 = [];
          let downline3 = [];
          let downline4 = [];
          if (downlinenode.length > 0) {
            for (let node in downlinenode) {
              var downlineCallContext = [
                {
                  reference: "details",
                  contractAddress: config.DefiQAddress,
                  abi: DefiQAbi,
                  calls: [
                    {
                      reference: "userprofile",
                      methodName: "users",
                      methodParameters: [downlinenode[node]],
                    },
                    {
                      reference: "viewUserReferral",
                      methodName: "viewUserReferral",
                      methodParameters: [downlinenode[node]],
                    },
                  ],
                },
              ];
              const results1 = await multicall.call(downlineCallContext);
              var userprofile1 = await getFormatMulticall(results1, "userprofile", 0);
              var downlinenode1 = await getFormatMulticall(results1, "viewUserReferral", 0);
              let userid1 = await bignumber(userprofile1[1]);
              let currlevel1 = await bignumber(userprofile1[3]);
              let downline_1 = {
                "address": downlinenode[node],
                "level": currlevel1,
                "userid": userid1,
                "downlinenode": downlinenode1
              }
              downline1.push(downline_1);
              downline2arr = downline2arr.concat(downlinenode1);
              if (parseFloat(node) + 1 == downlinenode.length) {
                setdownlinefirst(downline1);
              }
            }
          }
          //*******downline2*******//
          if (downline2arr.length > 0) {
            for (let node1 in downline2arr) {
              var downlineCallContext0 = [
                {
                  reference: "details",
                  contractAddress: config.DefiQAddress,
                  abi: DefiQAbi,
                  calls: [
                    {
                      reference: "userprofile",
                      methodName: "users",
                      methodParameters: [downline2arr[node1]],
                    },
                    {
                      reference: "viewUserReferral",
                      methodName: "viewUserReferral",
                      methodParameters: [downline2arr[node1]],
                    },
                  ],
                },
              ];
              const results2 = await multicall.call(downlineCallContext0);
              var userprofile2 = await getFormatMulticall(results2, "userprofile", 0);
              var downlinenode2 = await getFormatMulticall(results2, "viewUserReferral", 0);
              let userid2 = await bignumber(userprofile2[1]);
              let currlevel2 = await bignumber(userprofile2[3]);
              let downline_2 = {
                "address": downline2arr[node1],
                "level": currlevel2,
                "userid": userid2,
                "downlinenode": downlinenode2
              }
              downline2.push(downline_2);
              downline3arr = downline3arr.concat(downlinenode2);
              if (parseFloat(node1) + 1 == downline2arr.length) {
                setdownlinesecond(downline2);
              }
            }
          }

          //************downline3******************************//
          if (downline3arr.length > 0) {
            for (let node2 in downline3arr) {
              var downlineCallContext1 = [
                {
                  reference: "details",
                  contractAddress: config.DefiQAddress,
                  abi: DefiQAbi,
                  calls: [
                    {
                      reference: "userprofile",
                      methodName: "users",
                      methodParameters: [downline3arr[node2]],
                    },
                    {
                      reference: "viewUserReferral",
                      methodName: "viewUserReferral",
                      methodParameters: [downline3arr[node2]],
                    },
                  ],
                },
              ];
              const results3 = await multicall.call(downlineCallContext1);
              var userprofile3 = await getFormatMulticall(results3, "userprofile", 0);
              var downlinenode3 = await getFormatMulticall(results3, "viewUserReferral", 0);
              let userid3 = await bignumber(userprofile3[1]);
              let currlevel3 = await bignumber(userprofile3[3]);
              let downline_3 = {
                "address": downline3arr[node2],
                "level": currlevel3,
                "userid": userid3,
                "downlinenode": downlinenode3
              }
              downline3.push(downline_3);
              downline4arr = downline4arr.concat(downlinenode3);
              if (parseFloat(node2) + 1 == downline3arr.length) {
                setdownlinethird(downline3);
              }
            }
          }

          //************downline4******************************//
          if (downline4arr.length > 0) {
            for (let node3 in downline4arr) {
              var downlineCallContext3 = [
                {
                  reference: "details",
                  contractAddress: config.DefiQAddress,
                  abi: DefiQAbi,
                  calls: [
                    {
                      reference: "userprofile",
                      methodName: "users",
                      methodParameters: [downline4arr[node3]],
                    },
                    {
                      reference: "viewUserReferral",
                      methodName: "viewUserReferral",
                      methodParameters: [downline4arr[node3]],
                    },
                  ],
                },
              ];
              const results4 = await multicall.call(downlineCallContext3);
              var userprofile4 = await getFormatMulticall(results4, "userprofile", 0);
              var downlinenode4 = await getFormatMulticall(results4, "viewUserReferral", 0);
              let userid4 = await bignumber(userprofile4[1]);
              let currlevel4 = await bignumber(userprofile4[3]);
              let downline_4 = {
                "address": downline4arr[node3],
                "level": currlevel4,
                "userid": userid4,
                "downlinenode": downlinenode4
              }
              downline4.push(downline_4);
              downline5arr = downline5arr.concat(downlinenode4);
              if (parseFloat(node3) + 1 == downline4arr.length) {
                setdownlinefourth(downline4);
              }
            }
          }

          let line1arr = [];
          if (downline1.length > 0) {
            syncEach(downline1, async function (items, next) {
              if (items && items.downlinenode && items.downlinenode.length > 0) {
                let subline = [];
                syncEach(items.downlinenode, async function (subitems, next1) {
                  let IndexVal = downline2.findIndex(val => val.address == subitems);
                  if (IndexVal >= 0 && downline2[IndexVal].downlinenode && downline2[IndexVal].downlinenode.length > 0) {
                    let subline2 = [];
                    syncEach(downline2[IndexVal].downlinenode, async function (thir_items, next2) {
                      let IndexVal1 = downline3.findIndex(val => val.address == thir_items);
                      if (IndexVal1 >= 0 && downline3[IndexVal1].downlinenode && downline3[IndexVal1].downlinenode.length > 0) {
                        let subline3 = [];
                        syncEach(downline3[IndexVal1].downlinenode, async function (fourth_items, next3) {
                          let Index_Val = downline4.findIndex(val => val.address == fourth_items);
                          let user22 = {
                            name: "ID " + downline4[Index_Val].userid + " (" + downline4[Index_Val].level + ")"
                          }
                          subline3.push(user22);
                          next3();
                        }, function (error, success) {
                          let mainline = {
                            "name": "ID " + downline3[IndexVal1].userid + " (" + downline3[IndexVal1].level + ")",
                            "children": subline3
                          }
                          setlevel3(mainline);
                          subline2.push(mainline);
                          next2();
                        })
                      } else {
                        let IndexVal = downline3.findIndex(val => val.address == thir_items);
                        let user1 = {
                          name: "ID " + downline3[IndexVal].userid + " (" + downline3[IndexVal].level + ")"
                        }
                        subline2.push(user1);
                        next2();
                      }
                      // let user12 = {
                      //     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
                      // }
                      // subline2.push(user12);
                      // next2();
                    }, function (error, success) {
                      let mainline = {
                        "name": "ID " + downline2[IndexVal].userid + " (" + downline2[IndexVal].level + ")",
                        "children": subline2
                      }
                      setlevel2(mainline)
                      subline.push(mainline);
                      next1();
                    })
                  } else {
                    var IndexValue = downline2.findIndex(val => val.address == subitems);
                    let user1 = {
                      name: "ID " + downline2[IndexValue].userid + " (" + downline2[IndexValue].level + ")"
                    }
                    subline.push(user1);
                    next1();
                  }
                }, function (errs, t_Items) {
                  let mainline = {
                    "name": "ID " + items.userid + " (" + items.level + ")",
                    "children": subline
                  }
                  setlevel1(mainline)
                  console.log("push33")
                  line1arr.push(mainline);
                  next();
                })
              } else {
                let mainline = {
                  "name": "ID " + items.userid + " (" + items.level + ")",
                  "children": []
                }
                setlevel1(mainline);
                setlevel2([])
                line1arr.push(mainline);
                next();
              }
            }, function (err, transformedItems) {
              let mainlinearr = {
                name: "ID " + userid + " (" + currlevel + ")",
                "children": line1arr
              }
              setSelected(mainlinearr);
              settreeprogress(false)
            })
          } else {
            let mainlinearr = {
              name: "ID " + userid + " (" + currlevel + ")",
              "children": []
            }
            setSelected(mainlinearr);
            settreeprogress(false);
          }
        }
        else {

        }


      }
    }
  }

  async function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText = config.Front_URL + "join-now/" + useraddress;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  async function getlevelcount(){
  try{
      let get = await connection();
      if (get && get.web3) {
        const web3_bsc = new Web3(get.web3);//mainnet
        const defiqContract = new web3_bsc.eth.Contract(DefiQAbi, config.DefiQAddress);
        let referrallist = await defiqContract.methods.viewplanBUserReferral(get.address).call();
        var l1users = referrallist;
        var l2users = [];
        var l3users = [];
        var l4users = [];
        var l5users = [];
        var l6users = [];
        var l7users = [];
        var l8users = [];
        var l9users = [];
        var l10users = [];
        var l11users = [];
        var l12users = [];
        var l13users = [];
        var l14users = [];
        var l15users = [];
        var l16users = [];
        var l17users = [];
        var l18users = [];
        var l19users = [];
        var l20users = [];
        var l21users = [];
        var l22users = [];
        var l23users = [];
        var l24users = [];
        var l25users = [];
        setlevel1counts(l1users.length);
        syncEach(referrallist, async function (items, next) {
            let referrallist1 = await defiqContract.methods.viewplanBUserReferral(items).call();
            l2users = l2users.concat(referrallist1);
            next();
        },async function (err, transformedItems) {
            setlevel2counts(l2users.length)                                                                                          
            syncEach(l2users, async function (items1, next1) {
                let referrallist2 = await defiqContract.methods.viewplanBUserReferral(items1).call();
                l3users = l3users.concat(referrallist2);
                next1();
            },async function (err, transformedItems) {
                 setlevel3counts(l3users.length)                                                                                             
                syncEach(l3users, async function (items2, next2) {
                    let referrallist3 = await defiqContract.methods.viewplanBUserReferral(items2).call();
                    l4users = l4users.concat(referrallist3);
                    next2();
                },async function (err, transformedItems) {
                    setlevel4counts(l4users.length)                                                                                          
                    syncEach(l4users, async function (items3, next3) {
                        let referrallist4 = await defiqContract.methods.viewplanBUserReferral(items3).call();
                        l5users = l5users.concat(referrallist4);
                        next3();
                    },async function (err, transformedItems) {
                        setlevel5counts(l5users.length)                                                                                      
                        syncEach(l5users, async function (items4, next4) {
                            let referrallist5 = await defiqContract.methods.viewplanBUserReferral(items4).call();
                            l6users = l6users.concat(referrallist5);
                            next4();
                        },async function (err, transformedItems) {
                            setlevel6counts(l6users.length)                                                                                  
                            syncEach(l6users, async function (items5, next5) {
                                let referrallist6 = await defiqContract.methods.viewplanBUserReferral(items5).call();
                                l7users = l7users.concat(referrallist6);
                                next5();
                            },async function (err, transformedItems) {
                                 setlevel7counts(l7users.length)                                                                             
                                syncEach(l7users, async function (items6, next6) {
                                    let referrallist7 = await defiqContract.methods.viewplanBUserReferral(items6).call();
                                    l8users = l8users.concat(referrallist7);
                                    next6();
                                },async function (err, transformedItems) {
                                    setlevel8counts(l8users.length)                                                                          
                                    syncEach(l8users, async function (items7, next7) {
                                        let referrallist8 = await defiqContract.methods.viewplanBUserReferral(items7).call();
                                        l9users = l9users.concat(referrallist8);
                                        next7();
                                    },async function (err, transformedItems) {
                                        setlevel9counts(l9users.length)                                                                      
                                        syncEach(l9users, async function (items8, next8) {
                                            let referrallist9 = await defiqContract.methods.viewplanBUserReferral(items8).call();
                                            l10users = l10users.concat(referrallist9);
                                            next8();
                                        },async function (err, transformedItems) {
                                                                                                                                                         
                                          setlevel10counts(l10users.length) 
                                            syncEach(l10users, async function (items9, next9) {
                                                let referrallist10 = await defiqContract.methods.viewplanBUserReferral(items9).call();
                                                l11users = l11users.concat(referrallist10);
                                                next9();
                                            },async function (err, transformedItems) {
                                                 setlevel11counts(l11users.length)                                                                                                             

                                                syncEach(l11users, async function (items10, next10) {
                                                    let referrallist11 = await defiqContract.methods.viewplanBUserReferral(items10).call();
                                                    l12users = l12users.concat(referrallist11);
                                                    next10();
                                                },async function (err, transformedItems) {
                                                    setlevel12counts(l12users.length)                                                                                                              

                                                    syncEach(l12users, async function (items11, next11) {
                                                        let referrallist12 = await defiqContract.methods.viewplanBUserReferral(items11).call();
                                                        l13users = l13users.concat(referrallist12);
                                                        next11();
                                                    },async function (err, transformedItems) {
                                                         setlevel13counts(l13users.length)                                                                                                             

                                                        syncEach(l13users, async function (items12, next12) {
                                                            let referrallist13 = await defiqContract.methods.viewplanBUserReferral(items12).call();
                                                            l14users = l14users.concat(referrallist13);
                                                            next12();
                                                        },async function (err, transformedItems) {
                                                            setlevel14counts(l14users.length)                                                                                                          

                                                            syncEach(l14users, async function (items13, next13) {
                                                                let referrallist14 = await defiqContract.methods.viewplanBUserReferral(items13).call();
                                                                l15users = l15users.concat(referrallist14);
                                                                next13();
                                                            },async function (err, transformedItems) {
                                                                 setlevel15counts(l15users.length)                                                                                                             

                                                                syncEach(l15users, async function (items14, next14) {
                                                                    let referrallist15 = await defiqContract.methods.viewplanBUserReferral(items14).call();
                                                                    l16users = l16users.concat(referrallist15);
                                                                    next14();
                                                                },async function (err, transformedItems) {
                                                                       setlevel16counts(l16users.length)                                                                                                       

                                                                    syncEach(l16users, async function (items15, next15) {
                                                                        let referrallist16 = await defiqContract.methods.viewplanBUserReferral(items15).call();
                                                                        l17users = l17users.concat(referrallist16);
                                                                        next15();
                                                                    },async function (err, transformedItems) {
                                                                          setlevel17counts(l17users.length)                                                                                                        

                                                                        syncEach(l17users, async function (items16, next16) {
                                                                            let referrallist17 = await defiqContract.methods.viewplanBUserReferral(items16).call();
                                                                            l18users = l18users.concat(referrallist17);
                                                                            next16();
                                                                        },async function (err, transformedItems) {
                                                                                setlevel18counts(l18users.length)                                                                                                      

                                                                            syncEach(l18users, async function (items17, next17) {
                                                                                let referrallist18 = await defiqContract.methods.viewplanBUserReferral(items17).call();
                                                                                l19users = l19users.concat(referrallist18);
                                                                                next17();
                                                                            },async function (err, transformedItems) {
                                                                                     setlevel19counts(l19users.length)                                                                                                     

                                                                                syncEach(l19users, async function (items18, next18) {
                                                                                    let referrallist19 = await defiqContract.methods.viewplanBUserReferral(items18).call();
                                                                                    l20users = l20users.concat(referrallist19);
                                                                                    next18();
                                                                                },async function (err, transformedItems) {
                                                                                            setlevel20counts(l20users.length)                                                                                                  

                                                                                    syncEach(l20users, async function (items19, next19) {
                                                                                        let referrallist20 = await defiqContract.methods.viewplanBUserReferral(items19).call();
                                                                                        l21users = l21users.concat(referrallist20);
                                                                                        next19();
                                                                                    },async function (err, transformedItems) {
                                                                                                   setlevel21counts(l21users.length)                                                                                               

                                                                                        syncEach(l21users, async function (items20, next20) {
                                                                                            let referrallist21 = await defiqContract.methods.viewplanBUserReferral(items20).call();
                                                                                            l22users = l22users.concat(referrallist21);
                                                                                            next20();
                                                                                        },async function (err, transformedItems) {
                                                                                                    setlevel22counts(l22users.length)                                                                                                 

                                                                                            syncEach(l22users, async function (items21, next21) {
                                                                                                let referrallist22 = await defiqContract.methods.viewplanBUserReferral(items21).call();
                                                                                                l23users = l23users.concat(referrallist22);
                                                                                                next21();
                                                                                            },async function (err, transformedItems) {
                                                                                            setlevel23counts(l23users.length)
                                                                                                    syncEach(l23users, async function (items22, next22) {
                                                                                                        let referrallist23 = await defiqContract.methods.viewplanBUserReferral(items22).call();
                                                                                                        l24users = l24users.concat(referrallist23);
                                                                                                        next22();
                                                                                                    },async function (err, transformedItems) {
                                                                                                            setlevel24counts(l24users.length)                                                                                                     

                                                                                                        syncEach(l24users, async function (items23, next23) {
                                                                                                            let referrallist24 = await defiqContract.methods.viewplanBUserReferral(items23).call();
                                                                                                            l25users = l25users.concat(referrallist24);
                                                                                                            next23();
                                                                                                        },async function (err, transformedItems) {
                                                                                                            setlevel25counts(l25users.length)
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            })
                                                        })
                                                    })
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            })
        })
      }
    }catch(errs){
        console.log(errs,"======errororororororororor")
    }
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel">
            <div className="row">
              <div className="col-md-12">
                <div className="dash_title_flex">
                  <h2 className="inner_title">Dashboard</h2>
                  <div className="wallet_div">
                    <label>Affiliate Link:</label>
                    <div className="inp_grp">
                      <input
                        type="text"
                        className="form-control primary_inp"
                        // defaultValue="https://defiq.io/i/g9pnqp/"
                        value={config.Front_URL + "join-now/" + useraddress}
                      />
                      <button className="copy_btn" onClick={copyToClipboard}>
                        <img
                          src={require("../assets/images/copy_icon.png")}
                          className="img-fluid"
                          alt="Copy"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard_top_wrap">
              <div className="row">
                <div className="col-lg-3">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <img
                          src={require("../assets/images/dash_icon_01.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <div className="dashbox_middle_bottom">
                          <h2 className="mb-0">{userId ? userId : 0}</h2>
                          <h5>My ID</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <img
                          src={require("../assets/images/dash_icon_02.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <div className="dashbox_middle_bottom">
                          <h2 className="mb-0">{allusers ? allusers : 0}</h2>
                          <h5>All Participants</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <img
                          src={require("../assets/images/dash_icon_03.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <div className="dashbox_middle_bottom">
                          <h2 className="mb-0">{totalearnAmt && parseFloat(totalearnAmt) > 0 ? parseFloat(totalearnAmt).toFixed(4) : 0}</h2>
                          <h5>Total Earned USDT</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <img
                          src={require("../assets/images/dash_icon_02.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <div className="dashbox_middle_bottom">
                          <h2 className="mb-0">{dirReferralcnt && parseFloat(dirReferralcnt) > 0 ? dirReferralcnt : 0}</h2>
                          <h5>Direct Referral</h5>
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-lg-12">
                      <div className="primary_box dash_box side_box">
                        <img
                          src={require("../assets/images/dash_icon_02.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                        <div className="dashbox_middle_bottom">
                          <h2 className="mb-0">{userdetail && userdetail.teamCount  ? userdetail.teamCount : 0}</h2>
                          <h5>Total Team Members</h5>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="col-lg-9">
                  {/* Your Income */}
                  <div className="primary_box dash_box">
                    <div className="dashbox_middle_bottom">
                      <h2>Your Income</h2>
                      <div className="balance_box">
                        <div>
                          <label>Your Wallet Balance</label>
                          <label>
                            {BusdBal && parseFloat(BusdBal) > 0 ? parseFloat(BusdBal).toFixed(4) : 0} <span>USDT</span>
                          </label>
                        </div>
                        <div>
                          <label>Your Earned Amount</label>
                          <label>
                            {yourearnedAmt && parseFloat(yourearnedAmt) > 0 ? parseFloat(yourearnedAmt).toFixed(4) : 0} <span>USDT</span>
                          </label>
                        </div>
                      </div>
                      <div className="income_wrapper">
                        {/* <div className="income_div">
                          <h3>Defi Q Token Staking Income</h3>
                          <h4>
                            {locktokenAmt && parseFloat(locktokenAmt) > 0 ? parseFloat(locktokenAmt).toFixed(4) : 0} <span>DEFIQ</span>
                          </h4>
                        </div> */}
                        <div className="income_div">
                          <h3>Direct Referral Income</h3>
                          <h4>
                            {userdetail && userdetail.directreferralIncome && userdetail.directreferralIncome > 0 ? (parseFloat(userdetail.directreferralIncome) / 1e18).toFixed(2) : 0} <span>USDT</span>
                          </h4>
                        </div>
                        <div className="income_div">
                          <h3>Matrix Income</h3>
                          <h4>
                            {upgradematrixIncome && parseFloat(upgradematrixIncome) > 0 ? parseFloat(upgradematrixIncome).toFixed(4) : 0} <span>USDT</span>
                          </h4>
                        </div>
                        {/* <div className="income_div">
                          <h3>Upgrade Matrix Income</h3>
                          <h4>
                            {upgradematrixIncome && parseFloat(upgradematrixIncome) > 0 ? parseFloat(upgradematrixIncome).toFixed(4) : 0}  <span>USDT</span>
                          </h4>
                        </div> */}
                        <div className="income_div">
                          <h3>Generation Income</h3>
                          <h4>
                            {genertionIncome && parseFloat(genertionIncome) > 0 ? parseFloat(genertionIncome).toFixed(4) : 0} <span>USDT</span>
                          </h4>
                        </div>
                        <div className="income_div">
                          <h3>Team Royalty</h3>
                          <h4>
                            {RoyaltyIncome && parseFloat(RoyaltyIncome) > 0 ? parseFloat(RoyaltyIncome).toFixed(4) : 0} <span>USDT</span>
                          </h4>
                        </div>
                        <div className="income_div">
                          <h3>Global Royalty</h3>
                          <h4>
                            {globalroyality1income && parseFloat(globalroyality1income) > 0 ? parseFloat(globalroyality1income).toFixed(4) : 0} <span>USDT</span>
                          </h4>
                        </div>
                        <div className="income_div">
                          <h3>Total Locked Tokens</h3>
                          <h4>
                            {locktokenAmt && parseFloat(locktokenAmt) > 0 ? parseFloat(locktokenAmt).toFixed(4) : 0} <span>DEFIQ</span>
                          </h4>
                        </div>
                        <div className="income_div">
                          <h3>Available Withdraw</h3>
                          <h4>
                            {yourearnedAmt && parseFloat(yourearnedAmt) > 0 ? parseFloat(yourearnedAmt).toFixed(4) : 0} <span>USDT</span>
                          </h4>
                        </div>
                        {parseInt(currentLevel) != 10 &&
                          <div className="income_div">
                            <h3>Available Upgrade</h3>
                            <h4>
                              {upgradeAmount && parseFloat(upgradeAmount) > 0 ? parseFloat(upgradeAmount).toFixed(4) : 0} <span>USDT</span>
                            </h4>
                          </div>
                        }
                        <div className="income_div">
                          <h3>Total Earned DefiQ Token</h3>
                          <h4>
                            {userdetail && userdetail.totalearnedToken && parseFloat(userdetail.totalearnedToken) > 0 ? parseFloat(userdetail.totalearnedToken).toFixed(4) : 0} <span>DefiQ</span>
                          </h4>
                        </div>
                        {/*<div className="income_div">
                          <h3> Upgrade Pending</h3>
                          <h4>
                            {upgradePending && parseFloat(upgradePending) > 0 ? parseFloat(upgradePending).toFixed(4) : 0} <span>USDT</span>
                          </h4>
                        </div>*/}
                      </div>
                    </div>
                  </div>


                  {/* Upgrade Matrix Income */}
                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <div className="dash_flex_header">
                        <h2>Upgrade Matrix Income</h2>
                        <div className="dash_btn_grp">
                          {
                            parseInt(currentLevel) != 10 ? 
                              upgradeLoad == true ?
                                <button className="primary_btn disabled" disabled>Processing...</button> :
                                <button className="primary_btn" onClick={Upgrade}>Upgrade</button>
                            :""
                          }
                          {
                            withdrawLoad == true ?
                              <button className="primary_btn disabled" disabled>Processing...</button>
                              :
                              <button className="primary_btn" onClick={Withdraw}>Withdraw</button>
                          }
                          {parseInt(currentLevel) != 10 &&
                            <button className="primary_btn" data-bs-toggle="modal" data-bs-target="#wallet_modal">Deposit</button>
                          }
                          
                        </div>
                      </div>
                      <div className="plan_wrapper">

                        <div className="buy_item">
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>1</span></p>
                            </div>
                            <div className="text-center">
                              <h2>20 <span>USDT</span></h2>
                              <h4>Upgrade Completed</h4>
                              {/* <h4>Eligible Income</h4>
                              <h5>40 <span>USDT</span></h5> */}
                            </div>
                          </div>
                        </div>
                        <div className={currentLevel && parseInt(currentLevel) == 2 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 2 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 2 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>2</span></p>
                            </div>
                            <div className="text-center">
                              <h2>40 <span>USDT</span></h2>
                              {
                                parseInt(currentLevel) < 2 && parseInt(currentLevel) > 0 ?
                                  <>
                                    {/*<h4>Eligible Income</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) < 2) && l2_missedearnings && parseFloat(l2_missedearnings) > 0 ? l2_missedearnings : 0} <span>USDT</span></h5>*/}
                                    <h4>Upgrade Pending</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) + 1 == 2) && parseFloat(upgradePending) > 0 ?
                                      (40 - parseFloat(upgradePending)) : 40} <span>USDT</span></h5>
                                  </> : parseInt(currentLevel) >= 2 &&
                                  <>
                                    <h4>Upgrade Completed</h4>
                                  </>

                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 3 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 3 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 3 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>3</span></p>
                            </div>
                            <div className="text-center">
                              <h2>80 <span>USDT</span></h2>
                              {
                                parseInt(currentLevel) < 3 && parseInt(currentLevel) > 0 ?
                                  <>
                                    {/*<h4>Eligible Income</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) < 3) && l3_missedearnings && parseFloat(l3_missedearnings) > 0 ? l3_missedearnings : 0} <span>USDT</span></h5>*/}
                                    <h4>Upgrade Pending</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) + 1 == 3) && parseFloat(upgradePending) > 0 ?
                                      (80 - parseFloat(upgradePending)) : 80} <span>USDT</span></h5>
                                  </> : parseInt(currentLevel) >= 3 &&
                                  <>
                                    <h4>Upgrade Completed</h4>
                                  </>
                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 4 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 4 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 4 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>4</span></p>
                            </div>
                            <div className="text-center">
                              <h2>160 <span>USDT</span></h2>
                              {
                                parseInt(currentLevel) < 4 && parseInt(currentLevel) > 0 ?
                                  <>
                                    {/*<h4>Eligible Income</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) < 4) && l4_missedearnings && parseFloat(l4_missedearnings) > 0 ? l3_missedearnings : 0} <span>USDT</span></h5>*/}
                                    <h4>Upgrade Pending</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) + 1 == 4) && parseFloat(upgradePending) > 0 ?
                                      (160 - parseFloat(upgradePending)) : 160} <span>USDT</span></h5>
                                  </> : parseInt(currentLevel) >= 4 &&
                                  <>
                                    <h4>Upgrade Completed</h4>
                                  </>
                                }
                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 5 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 5 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 5 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>5</span></p>
                            </div>
                            <div className="text-center">
                              <h2>320 <span>USDT</span></h2>
                              {
                                parseInt(currentLevel) < 5 && parseInt(currentLevel) > 0 ?
                                  <>
                                    {/*<h4>Eligible Income</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) < 5) && l5_missedearnings && parseFloat(l5_missedearnings) > 0 ? l5_missedearnings : 0} <span>USDT</span></h5>*/}
                                    <h4>Upgrade Pending</h4>
                                    <h5>{currentLevel && (parseInt(currentLevel) + 1 == 5) && parseFloat(upgradePending) > 0 ?
                                      (320 - parseFloat(upgradePending)) : 320} <span>USDT</span></h5>
                                  </> : parseInt(currentLevel) >= 5 &&
                                  <>
                                    <h4>Upgrade Completed</h4>
                                  </>
                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 6 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 6 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 6 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>6</span></p>
                            </div>
                            <div className="text-center">
                              <h2>640 <span>USDT</span></h2>
                              {
                                parseInt(currentLevel) < 6 && parseInt(currentLevel) > 0 ? <>

                                  {/*<h4>Eligible Income</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) < 6) && l6_missedearnings && parseFloat(l6_missedearnings) > 0 ? l6_missedearnings : 0} <span>USDT</span></h5>*/}
                                  <h4>Upgrade Pending</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) + 1 == 6) && parseFloat(upgradePending) > 0 ?
                                    (640 - parseFloat(upgradePending)) : 640} <span>USDT</span></h5>
                                </> : parseInt(currentLevel) >= 6 &&
                                  <>
                                    <h4>Upgrade Completed</h4>
                                  </>
                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 7 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 7 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 7 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>7</span></p>
                            </div>
                            <div className="text-center">
                              <h2>1280 <span>USDT</span></h2>
                              {
                                parseInt(currentLevel) < 7 && parseInt(currentLevel) > 0 ? <>
                                  {/*<h4>Eligible Income</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) < 7) && l7_missedearnings && parseFloat(l7_missedearnings) > 0 ? l7_missedearnings : 0} <span>USDT</span></h5>*/}
                                  <h4>Upgrade Pending</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) + 1 == 7) && parseFloat(upgradePending) > 0 ?
                                    (1280 - parseFloat(upgradePending)) : 1280} <span>USDT</span></h5>
                                </> : parseInt(currentLevel) >= 7 &&
                                  <>
                                    <h4>Upgrade Completed</h4>
                                  </>
                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 8 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 8 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 8 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>8</span></p>
                            </div>
                            <div className="text-center">
                              <h2>2560 <span>USDT</span></h2>
                              {
                                parseInt(currentLevel) < 8 && parseInt(currentLevel) > 0 ? <>
                                  {/*<h4>Eligible Income</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) < 8) && l8_missedearnings && parseFloat(l8_missedearnings) > 0 ? l8_missedearnings : 0} <span>USDT</span></h5>*/}
                                  <h4>Upgrade Pending</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) + 1 == 8) && parseFloat(upgradePending) > 0 ?
                                    (2560 - parseFloat(upgradePending)) : 2560} <span>USDT</span></h5>
                                </> : parseInt(currentLevel) >= 8 &&
                                  <>
                                    <h4>Upgrade Completed</h4>
                                  </>
                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 9 ? "buy_item buy_item_upgraded" :
                          currentLevel && parseInt(currentLevel) + 1 == 9 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 9 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>9</span></p>
                            </div>
                            <div className="text-center">
                              <h2>5120 <span>USDT</span></h2>
                              {
                                parseInt(currentLevel) < 9 && parseInt(currentLevel) > 0 ? <>
                                  {/*<h4>Eligible Income</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) < 9) && l9_missedearnings && parseFloat(l9_missedearnings) > 0 ? l9_missedearnings : 0} <span>USDT</span></h5>*/}
                                  <h4>Upgrade Pending</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) + 1 == 9) && parseFloat(upgradePending) > 0 ?
                                    (5120 - parseFloat(upgradePending)) : 5120} <span>USDT</span></h5>
                                </> : parseInt(currentLevel) >= 9 &&
                                  <>
                                    <h4>Upgrade Completed</h4>
                                  </>
                              }

                            </div>
                          </div>
                        </div>

                        <div className={currentLevel && parseInt(currentLevel) == 10 ? "buy_item" :
                          currentLevel && parseInt(currentLevel) + 1 == 10 ? "buy_item buy_item_partial_upgraded" :
                            currentLevel && parseInt(currentLevel) < 10 ? "buy_item buy_item_disabled" : "buy_item"}>
                          <div className="buy_item_inner">
                            <div className="buy_count">
                              <p className="mb-0">Level <span>10</span></p>
                            </div>
                            <div className="text-center">
                              <h2>10240 <span>USDT</span></h2>
                              {
                                parseInt(currentLevel) < 10 && parseInt(currentLevel) > 0 ? <>
                                  {/*<h4>Eligible Income</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) < 10) && l10_missedearnings && parseFloat(l10_missedearnings) > 0 ? l10_missedearnings : 0} <span>USDT</span></h5>*/}
                                  <h4>Upgrade Pending</h4>
                                  <h5>{currentLevel && (parseInt(currentLevel) + 1 == 10) && parseFloat(upgradePending) > 0 ?
                                    (10240 - parseFloat(upgradePending)) : 10240} <span>USDT</span></h5>
                                </> : parseInt(currentLevel) >= 10 &&
                                  <>
                                    <h4>Upgrade Completed</h4>
                                  </>
                              }

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2>Genealogy Tree</h2>
                      {/* <div className="inp_grp search_div">
                      <input
                        type="text"
                        className="form-control primary_inp"
                        placeholder="Search by id"
                      />
                      <button>Search</button>
                    </div> */}

                      <div className="genealogy_tree">
                        {!treeprogress ?
                          <div>
                            <AnimatedTree
                              data={selected}
                              height={600}
                              width={800}
                              gProps={{ className: "node", onClick: handleClick }}
                              nodeShape="image"
                              nodeProps={{
                                href: config.Front_URL+"images/tree_icon.png",
                                width: "22",
                                height: "24",
                              }}
                              margins={{ top: 50, bottom: 50, left: 80, right: 80 }}
                              textProps={{ dx: -16, dy: 25 }}
                            />
                          </div>
                          :
                          <div>
                            <center><p className="text-danger mb-1">Processing....,Please Wait</p></center>
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2>Your Network</h2>
                      <div className="plan_income_table_div">
                        <div className="table-responsive">
                          <table className="table plan_income_table mt-0">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>User Id</th>
                                <th>Level</th>
                              </tr>
                            </thead>
                            <tbody>
                              {mynetwork && mynetwork.length > 0 && mynetwork.map((item, index) => {
                                console.log(item,"======mynetwork")
                                return (
                                  <tr>
                                    <td>{parseInt(index) + 1}</td>
                                    <td>
                                      {"ID "+ item.userid}
                                    </td>
                                    <td>{item.level}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="primary_box dash_box mt-4">
                    <div className="dashbox_middle_bottom">
                      <h2>Your Generation Income</h2>
                      <div className='plan_income_table_div'>
                        <div className='table-responsive'>
                          <table className='table plan_income_table mt-0'>
                            <thead>
                              <tr>
                                <th>Level</th>
                                <th>No of Users</th>
                                <th>Income (USDT)</th>
                              </tr>                                
                            </thead>
                              <tbody>
                                  <tr>
                                    <td>L1</td>
                                    <td>{level1counts}</td>
                                    <td>{(parseFloat(level1counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L2</td>
                                    <td>{level2counts}</td>
                                    <td>{(parseFloat(level2counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L3</td>
                                    <td>{level3counts}</td>
                                    <td>{(parseFloat(level3counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L4</td>
                                    <td>{level4counts}</td>
                                    <td>{(parseFloat(level4counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L5</td>
                                    <td>{level5counts}</td>
                                    <td>{(parseFloat(level5counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L6</td>
                                    <td>{level6counts }</td>
                                    <td>{(parseFloat(level6counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L7</td>
                                    <td>{level7counts }</td>
                                    <td>{(parseFloat(level7counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L8</td>
                                    <td>{level8counts}</td>
                                    <td>{(parseFloat(level8counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L9</td>
                                    <td>{level9counts}</td>
                                    <td>{(parseFloat(level9counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L10</td>
                                    <td>{level10counts}</td>
                                    <td>{(parseFloat(level10counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L11</td>
                                    <td>{level11counts}</td>
                                    <td>{(parseFloat(level11counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L12</td>
                                    <td>{level12counts}</td>
                                    <td>{(parseFloat(level12counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L13</td>
                                    <td>{level13counts}</td>
                                    <td>{(parseFloat(level13counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L14</td>
                                    <td>{level14counts}</td>
                                    <td>{(parseFloat(level14counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L15</td>
                                    <td>{level15counts }</td>
                                    <td>{(parseFloat(level15counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L16</td>
                                    <td>{level16counts}</td>
                                    <td>{(parseFloat(level16counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L17</td>
                                    <td>{level17counts}</td>
                                    <td>{(parseFloat(level17counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L18</td>
                                    <td>{level18counts}</td>
                                    <td>{(parseFloat(level18counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L19</td>
                                    <td>{level19counts}</td>
                                    <td>{(parseFloat(level19counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L20</td>
                                    <td>{level20counts}</td>
                                    <td>{(parseFloat(level20counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L21</td>
                                    <td>{level21counts}</td>
                                    <td>{(parseFloat(level21counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L22</td>
                                    <td>{level22counts}</td>
                                    <td>{(parseFloat(level22counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L23</td>
                                    <td>{level23counts}</td>
                                    <td>{(parseFloat(level23counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L24</td>
                                    <td>{level24counts }</td>
                                    <td>{(parseFloat(level24counts)*0.5).toFixed(5)}</td>
                                  </tr>
                                  <tr>
                                    <td>L25</td>
                                    <td>{level25counts}</td>
                                    <td>{(parseFloat(level25counts)*0.5).toFixed(5)}</td>
                                  </tr>

                                  
                                  <tr>     
                                    <td colspan={3}>
                                      <h4 className="text-center">{"Total Generation Income  (USDT) : "+
                                      ((level1counts ? (parseFloat(level1counts)*0.5): 0) + 
                                      (level2counts ? (parseFloat(level2counts)*0.5): 0) + 
                                      (level3counts  ? (parseFloat(level3counts)*0.5): 0) + 
                                      (level4counts  ? (parseFloat(level4counts)*0.5): 0) + 
                                      (level5counts  ? (parseFloat(level5counts)*0.5): 0) + 
                                      (level6counts  ? (parseFloat(level6counts)*0.5): 0) + 
                                      (level7counts  ? (parseFloat(level7counts)*0.5): 0) + 
                                      (level8counts  ? (parseFloat(level8counts)*0.5): 0) + 
                                      (level9counts  ? (parseFloat(level9counts)*0.5): 0) + 
                                      (level10counts  ? (parseFloat(level10counts)*0.5): 0) +
                                      (level11counts  ? (parseFloat(level11counts)*0.5): 0) +
                                      (level12counts  ? (parseFloat(level12counts)*0.5): 0) +
                                      (level13counts  ? (parseFloat(level13counts)*0.5): 0) +
                                      (level14counts  ? (parseFloat(level14counts)*0.5): 0) +
                                      (level15counts  ? (parseFloat(level15counts)*0.5): 0) +
                                      (level16counts  ? (parseFloat(level16counts)*0.5): 0) +
                                      (level17counts  ? (parseFloat(level17counts)*0.5): 0) +
                                      (level18counts  ? (parseFloat(level18counts)*0.5): 0) +
                                      (level19counts  ? (parseFloat(level19counts)*0.5): 0) +
                                      (level20counts  ? (parseFloat(level20counts)*0.5): 0) +
                                      (level21counts  ? (parseFloat(level21counts)*0.5): 0) +
                                      (level22counts  ? (parseFloat(level22counts)*0.5): 0) +
                                      (level23counts  ? (parseFloat(level23counts)*0.5): 0) +
                                      (level24counts  ? (parseFloat(level24counts)*0.5): 0) +
                                      (level25counts  ? (parseFloat(level25counts )*0.5): 0)).toFixed(5)
                                      } </h4>
                                    </td>
                                  </tr>
                              </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className="modal fade primary_modal" id="wallet_modal" tabIndex={-1} aria-labelledby="walletModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="walletModalLabel">Deposit</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <label for="exampleFormControlInput1" class="form-label">Invest Amount</label>
              <input type="Number" class="form-control primary_inp" id="exampleFormControlInput1" placeholder="Enter Invest Amount" value={depositamt} onChange={depositamtchange} min="0" max="160"/>
              <div className="mt-3">
                  <button className="primary_btn" type="button" onClick={depositLoad==false?deposit_busd:()=>empty()}>{depositLoad==false?"Submit":"Processing..Please Wait.."}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
