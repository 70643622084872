import axios from 'axios';
import config from '../config/config';


export const updateregister = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/register`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const get_levelcounts = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getlevelcount`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const checkuser = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/checkuser`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const get_planAlist = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/planAlist`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const get_eligible = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getelligibles`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const updatemissed = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/register`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}


export const get_emergencydistributedetails = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/Emergencydistribute`,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const canceldistribute = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/canceldistribute`,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const updatedistribute = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/updatedistribute`,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const get_distributedetails = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/distributedetails`,
      data : payload
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const getrecords = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getrecords`,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const levelupgrade = async (payload) => {
    console.log('payload: ', payload);
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/levelupgrade`,
      data: payload,
    });
    console.log(resp.data,'ssssssrespdatarespdatarespdata')
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log('err: ', err);

  }
};

