import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import "react-tree-graph/dist/style.css";
import { toastAlert } from "../helper/toastAlert.js";
import { useParams } from "react-router-dom";
import config from '../config/config'
import { connection } from "../helper/connection.js";
import DefiQABI from '../ABI/DefiQABI.json'
import { getrecords, get_emergencydistributedetails ,get_distributedetails, updatedistribute, canceldistribute } from "../Action/apiAction";
import moment from 'moment';
import Countdown, { zeroPad } from "react-countdown";
import { convert } from "../helper/convert.js";
import { type WalletClient, useWalletClient } from 'wagmi';
import Web3 from "web3";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Setting(props) {
    var { id } = useParams();
    const [progress, setprogress] = useState("false");
    const [autoprogress, setautoprogress] = useState("false");
    const [withdrawprogress, setwithdrawprogress] = useState("false");
    const [countprogress, setcountprogress] = useState("false");
    const [inputaddress, setinputaddress] = useState("");
    const [amount, setamount] = useState("");
    const [count, setcount] = useState(0);
    const [totaluser,settotaluser] =useState(0);
    const [records, setrecords]=useState([]);
    const [lastrewardtime,setlastrewardtime] = useState("");
    const [emer_progress,setemer_progress] = useState("false");
    const [royprogress,setroyprogress] = useState("false");
    const [roy1progress,setroy1progress] = useState("false");
    const [inputroyaddress,setinputroyaddress] = useState("");
    const [inputroy1address,setinputroy1address] = useState("");
    const [removeroy,setremoveroy] = useState("");
    const [removeroy1,setremoveroy1] = useState("");
    const [reroyprogress,setreroyprogress] = useState("false");
    const [reroy1progress,setreroy1progress] = useState("false");
    let chainid = config.NetworkId ;
    const { data: walletClient } = useWalletClient({ chainid })
  
    useEffect(() => {
      getdetails();
      getrec();
    }, [walletClient]);
  
    async function getrec() {
      let datas = await getrecords();
      if (datas && datas.data && datas.data.data) {
        setrecords(datas.data.data)
        let usercount =  (datas.data.data).filter(item => item.royality>0 || item.globalroyality_A>0 ).length
        settotaluser(usercount);
      }
    }
  
    async function getdetails() {
        var get = await connection();
        if(get && get.web3){
            var web3 = new Web3(get.web3)
            var deficontract = new web3.eth.Contract(DefiQABI, config.DefiQAddress);
            let lasttimestamp = await deficontract.methods.lastRewardTimestamp().call();
            lasttimestamp = moment.unix(lasttimestamp).toDate();
            lasttimestamp = moment(lasttimestamp).add(7, 'days');
            setlastrewardtime(lasttimestamp);
        }
        else{

        }
   
    }
  
    async function empty() {
  
    }

    const addressroyremovechange = (e) => {
      setremoveroy(e.target.value);
    }

    const addressroy1removechange = (e) => {
      setremoveroy1(e.target.value);
    }
  
    const addresschange = (e) => {
      setinputaddress(e.target.value);
    }
  
    const amountchange = (e) => {
      setamount(e.target.value);
    }
  
    const countchange = (e) => {
      setcount(e.target.value);
    }

    const addressroychange = (e) =>{
      setinputroyaddress(e.target.value)
    }

    const addressroy1change = (e) =>{
      setinputroy1address(e.target.value)
    }
  
    async function emergency(){
      setemer_progress("true");
      await get_emergencydistributedetails();
      await getrec();
      setemer_progress("false");
    }
  
    async function submit(num) {
      try {
        setprogress("true")
        var get = await connection();
        if(get && get.web3){
          let web3 = new Web3(get.web3)
          let address = get.address
          var defiQContract = new web3.eth.Contract(DefiQABI, config.DefiQAddress);
          let owneraddress = await defiQContract.methods.owner().call();
          console.log("hii")
          if ((owneraddress).toLowerCase() == (address).toLowerCase()) {
            let datass = {
              "skip": num == 1 ? 0 : num == 2 ? 500 : num ==3 ? 1000 :  num ==4 ? 1500 : 2000,
              "limit": num == 1 ? 500 : num == 2 ? 1000 : num ==3 ? 1500 :  num ==4 ? 2000 : 2500
            }
            let distribut_details = await get_distributedetails(datass);
            if(distribut_details && distribut_details.data && distribut_details.data.status && distribut_details.data.length>0 ){
              console.log(distribut_details.data.length,"1", distribut_details.data.address,"2", distribut_details.data.ramount,"3", distribut_details.data.g1amaount,"4","=5=======")
            let register = await defiQContract.methods.distributeToRoyal(distribut_details.data.length, distribut_details.data.address, distribut_details.data.ramount, distribut_details.data.g1amaount).send({
              from: address
            })
            if (register && register.status && register.transactionHash) {
              await updatedistribute();
              setprogress("false")
              toastAlert('success', "Distributed Successfully", 'network');
              window.location.reload();
            } else {
              await canceldistribute();
              setprogress("false")
              toastAlert('success', "Failed , try again later", 'network');
            }
        }else{
          setprogress("false")
          toastAlert('error', distribut_details.data.message, 'network');
        }
         } else {
            setprogress("false")
            toastAlert('error', "Login into Admin Address", 'network');
          }
  
        } else {
          setprogress("false")
          toastAlert('error', "Please select BSC chain network", 'network');
        }
      } catch (err) {
        console.log(err,"=====err")
        await canceldistribute();
        setprogress("false")
      }
    }
  
    async function withdraw() {
      try {
        setwithdrawprogress("true")
        var get = await connection();
        if(get && get.web3){
          if (parseFloat(amount) > 0) {
            let web3 = new Web3(get.web3);
            let address = get.address
            if (web3.utils.isAddress(inputaddress)) {
              var defiQContract = new web3.eth.Contract(DefiQABI, config.DefiQAddress);
              let owneraddress = await defiQContract.methods.owner().call();
              if ((owneraddress).toLowerCase() == (address).toLowerCase()) {
                let withamt = parseFloat(amount) * 1e18
                let sendamt = await convert(withamt);
                let register = await defiQContract.methods.safeWithDraw(sendamt.toString(), inputaddress).send({
                  from: address
                })
                if (register && register.status && register.transactionHash) {
                  setwithdrawprogress("false")
                  toastAlert('success', "Withdraw doned Successfully", 'network');
                  window.location.reload();
                } else {
                  setwithdrawprogress("false")
                  toastAlert('success', "Failed , try again later", 'network');
                }
              } else {
                setwithdrawprogress("false")
                toastAlert('error', "Login into Admin Address", 'network');
              }
            } else {
              setwithdrawprogress("false")
              toastAlert('error', "Enter Valid Address", 'network');
            }
          } else {
            setwithdrawprogress("false")
            toastAlert('error', "Enter Valid Amount", 'network');
          }
        } else {
          setwithdrawprogress("false")
          toastAlert('error', "Please select BSC chain network", 'network');
        }
      } catch (err) {
        setwithdrawprogress("false")
      }
    }

    async function addroy() {
      try {
        setroyprogress("true")
        var get = await connection();
        if(get && get.web3){
            let web3 = new Web3(get.web3);
            let address = get.address
            if (web3.utils.isAddress(inputroyaddress)) {
              var defiQContract = new web3.eth.Contract(DefiQABI, config.DefiQAddress);
              let owneraddress = await defiQContract.methods.owner().call();
              if ((owneraddress).toLowerCase() == (address).toLowerCase()) {
                let register = await defiQContract.methods.addTeamRotalParticipants(inputroyaddress).send({
                  from: address
                })
                if (register && register.status && register.transactionHash) {
                  setroyprogress("false")
                  toastAlert('success', "Added Successfully", 'network');
                  window.location.reload();
                } else {
                  setroyprogress("false")
                  toastAlert('success', "Failed , try again later", 'network');
                }
              } else {
                setroyprogress("false")
                toastAlert('error', "Login into Admin Address", 'network');
              }
            } else {
              setroyprogress("false")
              toastAlert('error', "Enter Valid Address", 'network');
            }
        } else {
          setroyprogress("false")
          toastAlert('error', "Please select BSC chain network", 'network');
        }
      } catch (err) {
        setroyprogress("false")
      }
    }

    async function addroy1() {
      try {
        setroy1progress("true")
        var get = await connection();
        if(get && get.web3){
            let web3 = new Web3(get.web3);
            let address = get.address
            if (web3.utils.isAddress(inputroy1address)) {
              var defiQContract = new web3.eth.Contract(DefiQABI, config.DefiQAddress);
              let owneraddress = await defiQContract.methods.owner().call();
              if ((owneraddress).toLowerCase() == (address).toLowerCase()) {
                let register = await defiQContract.methods.addGlobalParticipants(inputroy1address).send({
                  from: address
                })
                if (register && register.status && register.transactionHash) {
                  setroy1progress("false")
                  toastAlert('success', "Added Successfully", 'network');
                  window.location.reload();
                } else {
                  setroy1progress("false")
                  toastAlert('success', "Failed , try again later", 'network');
                }
              } else {
                setroy1progress("false")
                toastAlert('error', "Login into Admin Address", 'network');
              }
            } else {
              setroy1progress("false")
              toastAlert('error', "Enter Valid Address", 'network');
            }
        } else {
          setroy1progress("false")
          toastAlert('error', "Please select BSC chain network", 'network');
        }
      } catch (err) {
        setroy1progress("false")
      }
    }

    async function removeroyaddr() {
      try {
        setreroyprogress("true")
        var get = await connection();
        if(get && get.web3){
            let web3 = new Web3(get.web3);
            let address = get.address
            if (web3.utils.isAddress(removeroy)) {
              var defiQContract = new web3.eth.Contract(DefiQABI, config.DefiQAddress);
              let owneraddress = await defiQContract.methods.owner().call();
              if ((owneraddress).toLowerCase() == (address).toLowerCase()) {
                let register = await defiQContract.methods.removeTeamRoyalAddress(removeroy).send({
                  from: address
                })
                if (register && register.status && register.transactionHash) {
                  setreroyprogress("false")
                  toastAlert('success', "Removed Successfully", 'network');
                  window.location.reload();
                } else {
                  setreroyprogress("false")
                  toastAlert('success', "Failed , try again later", 'network');
                }
              } else {
                setreroyprogress("false")
                toastAlert('error', "Login into Admin Address", 'network');
              }
            } else {
              setreroyprogress("false")
              toastAlert('error', "Enter Valid Address", 'network');
            }
        } else {
          setreroyprogress("false")
          toastAlert('error', "Please select BSC chain network", 'network');
        }
      } catch (err) {
        setreroyprogress("false")
      }
    }

    async function removeroy1addr() {
      try {
        setreroy1progress("true")
        var get = await connection();
        if(get && get.web3){
            let web3 = new Web3(get.web3);
            let address = get.address
            if (web3.utils.isAddress(removeroy1)) {
              var defiQContract = new web3.eth.Contract(DefiQABI, config.DefiQAddress);
              let owneraddress = await defiQContract.methods.owner().call();
              if ((owneraddress).toLowerCase() == (address).toLowerCase()) {
                let register = await defiQContract.methods.removeGlobalAddress(removeroy1).send({
                  from: address
                })
                if (register && register.status && register.transactionHash) {
                  setreroy1progress("false")
                  toastAlert('success', "Removed Successfully", 'network');
                  window.location.reload();
                } else {
                  setreroy1progress("false")
                  toastAlert('success', "Failed , try again later", 'network');
                }
              } else {
                setreroy1progress("false")
                toastAlert('error', "Login into Admin Address", 'network');
              }
            } else {
              setreroy1progress("false")
              toastAlert('error', "Enter Valid Address", 'network');
            }
        } else {
          setreroy1progress("false")
          toastAlert('error', "Please select BSC chain network", 'network');
        }
      } catch (err) {
        setreroy1progress("false")
      }
    }

      
    const currentDate = new Date();
    const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

    const renderer = ({ days, hours, minutes, seconds }) => {
      return (
        <div className="timer_panel">
          <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">Days</span></span>
          <span className="timer_dots">:</span>
          <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">Hours</span></span>
          <span className="timer_dots">:</span>
          <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">Mins</span></span>
          <span className="timer_dots">:</span>
          <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">Secs</span></span>
        </div>
      );
    };
  
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="dashboard_panel join_now_panel">
            <div className="row">
              <div className="col-md-12 col-lg-8 m-auto">
                <div className="title_flex">
                  <h2 className="inner_title">Settings</h2>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                        <div className="form-group row">
                          <div className="col-lg-12 text-start">
                            <label for="exampleFormControlInput1" class="form-label">Distribute Team and Global Royalty</label>
                            <label for="exampleFormControlInput1" class="form-label">{"(Users Count:" + totaluser + " )"}</label>
                            <button type="button" className="primary_btn" onClick={progress == "false" ? () => submit(1) : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || royprogress== "true" || roy1progress == "true" || reroyprogress == "true" || reroy1progress == "true" || countprogress == "true") ? "disabled" : ""}>{progress == "false" ? "Distribute" : "Processing..Please Wait.."}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                        <p className="text-danger mb-1">If any emergency, You can distribute Here.</p>
                        {emer_progress=="true" && 
                          <p className="text-danger mb-1">Wait for 20 Minutes</p>
                        }
                        {emer_progress=="true"?
                          <button type="button" className="primary_btn">Please Wait..</button>
                          :
                          <button type="button" className="primary_btn" onClick={()=>emergency()}>Emergency Distribute</button>
                        }
                        <div className="timer"> 
                        {lastrewardtime!="" &&                        
                          <Countdown date={new Date(lastrewardtime)} 
                                  autoStart={true}
                                  onStart={() => new Date(lastrewardtime)} renderer={renderer} />  
                        }                       
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="primary_box dash_box mt-4">
                        <div className="form-group row">
                          <div className="col-lg-12 text-start">
                            <label for="exampleFormControlInput1" class="form-label">Safe Withdraw</label>
                            <input type="text" value={amount} class="form-control mb-3 primary_inp" id="exampleFormControlInput11" placeholder="Enter Amount USDT" onChange={amountchange} />
                            <input type="text" value={inputaddress} class="form-control primary_inp" id="exampleFormControlInput11" placeholder="Enter Address" onChange={addresschange} />
                            <button type="button" className="primary_btn" onClick={withdrawprogress == "false" ? () => withdraw() : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || royprogress== "true" || roy1progress == "true" || reroyprogress == "true" || reroy1progress == "true" || countprogress == "true") ? "disabled" : ""}>{withdrawprogress == "false" ? "Withdraw" : "Processing..Please Wait.."}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                        <div className="form-group row">
                          <div className="col-lg-12 text-start">
                            <label for="exampleFormControlInput1" class="form-label">Add Royalty participants</label>
                            <input type="text" value={inputroyaddress} class="form-control primary_inp" id="exampleFormControlInput11" placeholder="Enter Address" onChange={addressroychange} />
                            <button type="button" className="primary_btn" onClick={royprogress == "false" ? () => addroy() : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || royprogress== "true" || roy1progress == "true" || reroyprogress == "true" || reroy1progress == "true" || countprogress == "true") ? "disabled" : ""}>{royprogress == "false" ? "Add" : "Processing..Please Wait.."}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                        <div className="form-group row">
                          <div className="col-lg-12 text-start">
                            <label for="exampleFormControlInput1" class="form-label">Add Global Royalty Paricipants</label>
                            <input type="text" value={inputroy1address} class="form-control primary_inp" id="exampleFormControlInput11" placeholder="Enter Address" onChange={addressroy1change} />
                            <button type="button" className="primary_btn" onClick={roy1progress == "false" ? () => addroy1() : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || royprogress== "true" || roy1progress == "true" || reroyprogress == "true" || reroy1progress == "true" || countprogress == "true") ? "disabled" : ""}>{roy1progress == "false" ? "Add" : "Processing..Please Wait.."}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                        <div className="form-group row">
                          <div className="col-lg-12 text-start">
                            <label for="exampleFormControlInput1" class="form-label">Remove Royalty participants</label>
                            <input type="text" value={removeroy} class="form-control primary_inp" id="exampleFormControlInput11" placeholder="Enter Address" onChange={addressroyremovechange} />
                            <button type="button" className="primary_btn" onClick={reroyprogress == "false" ? () => removeroyaddr() : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || royprogress== "true" || roy1progress == "true" || reroyprogress == "true" || reroy1progress == "true" || countprogress == "true") ? "disabled" : ""}>{reroyprogress == "false" ? "Remove" : "Processing..Please Wait.."}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                        <div className="form-group row">
                          <div className="col-lg-12 text-start">
                            <label for="exampleFormControlInput1" class="form-label">Remove Global Royalty Paricipants</label>
                            <input type="text" value={removeroy1} class="form-control primary_inp" id="exampleFormControlInput11" placeholder="Enter Address" onChange={addressroy1removechange} />
                            <button type="button" className="primary_btn" onClick={reroy1progress == "false" ? () => removeroy1addr() : () => empty()} disabled={(progress == "true" || autoprogress == "true" || withdrawprogress == "true" || royprogress== "true" || roy1progress == "true" || reroyprogress == "true" || reroy1progress == "true" || countprogress == "true") ? "disabled" : ""}>{reroy1progress == "false" ? "Remove" : "Processing..Please Wait.."}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="primary_box dash_box mt-4">
                      <div className="dashbox_middle_bottom">
                        <h2>Distribution</h2>
                        <div className='plan_income_table_div'>
                          <div className='table-responsive'>
                            <table className='table plan_income_table mt-0'>
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>UserId</th>
                                  <th>Address</th>
                                  <th>Level</th>
                                  <th>Royalty</th>
                                  <th>Global Royalty A</th>
                                </tr>
                              </thead>
                              <tbody>
                                {records && records.length > 0 && records.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{parseInt(index) + 1}</td>
                                      <td>{item.userid}</td>
                                      <td>{item.address}</td>
                                      <td>{item.level}</td>
                                      <td>{((Math.round(item.royality)) / 10000000).toFixed(4)}</td>
                                      <td>{((Math.round(item.globalroyality_A)) / 1000000000000000000).toFixed(4)}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
